import { styled } from "@mui/material";
import type { PictureSchema } from "~/schemas";

interface DtwImageProps {
  picture: PictureSchema;
  alt: string;
  className?: string;
}

function DtwImage({ picture, alt, className }: DtwImageProps) {
  return (
    <picture className={className} style={{ objectFit: "cover" }}>
      {!!picture?.min1024 && (
        <source
          media="(min-width: 1024px)"
          srcSet={picture?.min1024}
          style={{ objectFit: "cover" }}
        />
      )}
      {!!picture?.min768 && (
        <source
          media="(min-width: 768px)"
          srcSet={picture?.min768}
          style={{ objectFit: "cover" }}
        />
      )}
      {!!picture && (
        <img
          src={picture.default}
          alt={alt}
          className={className}
          style={{ objectFit: "cover" }}
        />
      )}
      {!picture && (
        <img
          src={"/assets/fashionshop/placeholder.png"}
          alt={alt}
          className={className}
          style={{ objectFit: "cover" }}
        />
      )}
    </picture>
  );
}

export default styled(DtwImage)``;
